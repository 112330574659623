<template>
  <div>
    <div style="height: 30px"></div>

    <div>
      <v-row no-gutters>
        <v-col>
          <div id="account-transactions-list" class="account-list-expansion-panel">
            <tracking-filter v-model="isFilterTrackingSidebarActive" :send-data="sendData" @filter-data="filterData" />

            <v-card
              tile
              :flat="$vuetify.breakpoint.mdAndDown"
              :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
            >
              <v-container v-if="routeId">
                <v-row>
                  <v-col cols="12" md="4" lg="3">
                    <v-row>
                      <v-col sm="3" md="12">
                        <v-card class="pa-5 pb-6 pa-md-0 pb-md-0" rounded="lg" height="100%">
                          <div>
                            <img :src="this.accountTable.bank_logo" height="100" />
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="8" lg="9">
                    <v-row>
                      <v-col cols="12">
                        <v-row
                          v-if="$vuetify.breakpoint.xsOnly"
                          style="position: relative; height: auto; margin-bottom: 2"
                        >
                          <v-col cols="12" sm="4" class="pa-2" style="z-index: 2; position: relative">
                            <div>
                              <row>
                                <!-- Main Card Hesap Bilgileri -->
                                <app-card-actions
                                  action-collapse
                                  :collapsed="false"
                                  @refresh="refetchData"
                                  class="mb-1"
                                >
                                  <template slot="title"> Hesap Bilgileri </template>
                                  <v-divider></v-divider>
                                  <template slot="after-actions">
                                    <v-chip color="success" label outlined small>
                                      {{ this.accountTable.bakiye | currency({ symbol: this.accountTable.dovizKodu }) }}
                                    </v-chip>
                                  </template>
                                  <v-card-text>
                                    <div class="text-xs mb-0">IBAN:</div>
                                    <div class="text-caption white--text font-weight-light pb-2">
                                      {{ this.accountTable.IBAN }}
                                    </div>
                                    <div class="text-xs mb-0">Hesap No:</div>
                                    <div class="text-caption white--text font-weight-light pb-2">
                                      {{ this.accountTable.hesapNo }}
                                    </div>
                                    <div class="text-xs mb-0">Hesap Adı:</div>
                                    <div class="text-caption white--text font-weight-light pb-2">
                                      {{ this.accountTable.hesapTuruTxt }}
                                    </div>
                                  </v-card-text>
                                </app-card-actions>
                              </row>
                            </div>
                          </v-col>

                          <v-col cols="12" sm="8" style="z-index: 1; position: relative">
                            <!-- Main Card Diğer Hesaplar -->
                            <app-card-actions
                              action-collapse
                              class="app-card-actions-mobil"
                              :collapsed="false"
                              @refresh="refetchData"
                            >
                              <template slot="title">Diğer Hesaplar </template>
                              <v-divider></v-divider>
                              <template slot="after-actions">
                                <v-chip color="success" label outlined small>
                                  {{ this.otherAccountsTable.length }} Hesabınız Var
                                </v-chip>
                              </template>
                              <v-card-text>
                                <v-simple-table>
                                  <tbody>
                                    <tr v-for="(item, index) in this.otherAccountsTable" :key="index">
                                      <td style="width: 100px">
                                        {{ item.subeAdi }}
                                      </td>
                                      <td style="width: 20px">
                                        {{ item.dovizKodu }}
                                      </td>
                                      <td
                                        style="width: 150px; text-align: right"
                                        :class="{ 'text-danger': item.bakiye < 0 }"
                                      >
                                        {{ item.bakiye | currency }}
                                      </td>

                                      <td style="white-space: nowrap">
                                        <!-- Bu satır eklendi -->
                                        <a @click.prevent="navigateToTransaction(item.id)">Hesaba Git</a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </v-simple-table>
                              </v-card-text>
                            </app-card-actions>
                          </v-col>
                        </v-row>

                        <v-row v-else style="position: relative; height: auto; margin-bottom: 0">
                          <v-col cols="12" sm="5" class="pa-0" style="z-index: 2; position: relative">
                            <!-- Main Card Hesap Bilgileri -->
                            <app-card-actions action-collapse :collapsed="false" @refresh="refetchData">
                              <template slot="title"> Hesap Bilgileri </template>
                              <v-divider></v-divider>
                              <template slot="after-actions">
                                <v-chip color="success" label outlined small>
                                  {{ this.accountTable.bakiye | currency({ symbol: this.accountTable.dovizKodu }) }}
                                </v-chip>
                              </template>
                              <v-card-text>
                                <div class="text-xs mb-0">IBAN:</div>
                                <div class="text-caption white--text font-weight-light pb-2">
                                  {{ this.accountTable.IBAN }}
                                </div>
                                <div class="text-xs mb-0">Hesap No:</div>
                                <div class="text-caption white--text font-weight-light pb-2">
                                  {{ this.accountTable.hesapNo }}
                                </div>
                                <div class="text-xs mb-0">Hesap Adı:</div>
                                <div class="text-caption white--text font-weight-light pb-2">
                                  {{ this.accountTable.hesapTuruTxt }}
                                </div>
                              </v-card-text>
                            </app-card-actions>
                          </v-col>

                          <v-col cols="12" sm="7" class="pa-0" style="z-index: 1; position: relative">
                            <!-- Main Card Diğer Hesaplar -->
                            <app-card-actions
                              action-collapse
                              class="app-card-actions mb-0"
                              :collapsed="true"
                              @refresh="refetchData"
                            >
                              <template slot="title"> Diğer Hesaplar </template>
                              <v-divider></v-divider>
                              <template slot="after-actions">
                                <v-chip color="success" label outlined small>
                                  {{ this.otherAccountsTable.length }} Hesabınız Var
                                </v-chip>
                              </template>
                              <v-card-text>
                                <v-simple-table>
                                  <tbody>
                                    <tr v-for="(item, index) in this.otherAccountsTable" :key="index">
                                      <td style="width: 100px">
                                        {{ item.subeAdi }}
                                      </td>
                                      <td style="width: 20px">
                                        {{ item.dovizKodu }}
                                      </td>

                                      <td
                                        style="width: 150px; text-align: right"
                                        :class="{ 'text-danger': item.bakiye < 0 }"
                                      >
                                        {{ item.bakiye | currency }}
                                      </td>

                                      <!--    <td
                                        :style="{
                                          width: '80px',
                                          textAlign: 'right',
                                          color: item.bakiye < 0 ? 'red' : 'inherit',
                                        }"
                                      >
                                        {{ item.bakiye | currency }}
                                      </td> -->
                                      <td style="white-space: nowrap">
                                        <!-- Bu satır eklendi -->
                                        <a :href="`/accounts/transactions/list/${item.id}`">Hesaba Git</a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </v-simple-table>
                              </v-card-text>
                            </app-card-actions>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-card
              tile
              :flat="$vuetify.breakpoint.mdAndDown"
              :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
            >
              <v-toolbar
                v-if="!$vuetify.breakpoint.smAndDown"
                :color="$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent'"
                :flat="!$vuetify.breakpoint.smAndDown"
                :height="$vuetify.breakpoint.smAndDown ? 120 : 87"
              >
                <v-toolbar-title v-if="routeId" class="text-body-2 text-md-h6 font-weight-medium">
                  <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                    {{ icons.mdiBankTransfer }}
                  </v-icon>
                  Hesap Hareketleri
                </v-toolbar-title>
                <v-toolbar-title v-else class="text-body-2 text-md-h6 font-weight-medium">
                  <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                    {{ icons.mdiBankTransfer }}
                  </v-icon>
                  Tüm Hesap Hareketleri
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <download-excel
                  v-if="!$vuetify.breakpoint.smAndDown"
                  :data="json_data"
                  :fields="json_fields"
                  name="HESAP__HAREKETLERI__FINEKSTRE.xls"
                >
                  <v-btn plain text small>
                    <v-icon left>
                      {{ icons.mdiDownload }}
                    </v-icon>
                    İndir
                  </v-btn>
                </download-excel>

                <v-btn plain text small @click.stop="isFilterTrackingSidebarActive = !isFilterTrackingSidebarActive">
                  <v-badge v-if="isFilterNumber" overlap color="tertiary" dot offset-x="15" offset-y="5">
                    <v-icon left>
                      {{ icons.mdiFilter }}
                    </v-icon>
                  </v-badge>
                  <v-icon v-else left>
                    {{ icons.mdiFilter }}
                  </v-icon>
                  Filtre
                </v-btn>

                <v-btn
                  class="text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 bg-gradient-primary white--text"
                  depressed
                  x-large
                  @click="goBank()"
                >
                  <span>Banka Hesaplarına Git</span>
                </v-btn>
              </v-toolbar>

              <v-expansion-panels v-else v-model="panel" flat tile>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-body-2 font-weight-medium">
                      <v-icon left>
                        {{ icons.mdiAccountSupervisor }}
                      </v-icon>
                      Hesap Hareketleri
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pa-0">
                    <v-divider></v-divider>
                    <v-list class="pa-0" color="grey">
                      <v-list-item @click="goBank()">
                        <v-list-item-content>
                          <v-list-item-title>Banka Hesaplarına Git</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-icon>{{ icons.mdiArrowRightBold }}</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-divider></v-divider>

                      <v-list class="pa-0" color="grey">
                        <v-list-item @click.stop="isFilterTrackingSidebarActive = !isFilterTrackingSidebarActive">
                          <v-list-item-content>
                            <v-list-item-title>Filtre</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon>
                            <v-icon :color="isFilterNumber ? 'tertiary' : null">
                              {{ icons.mdiFilter }}
                            </v-icon>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- table -->
              <v-sheet :style="$vuetify.breakpoint.xsOnly ? 'padding-top:30px' : null">
                <v-data-table
                  :item-key="'unique_id'"
                  :headers="tableColumns"
                  :items="filteredData"
                  :sort-desc="true"
                  :loading="loading"
                  fixed-header
                  :items-per-page="itemsPerPage"
                  :options.sync="options"
                  :server-items-length="totalDataListTable"
                  :footer-props="{ 'items-per-page-options': [30, 50, 100, 250, -1], showFirstLastPage: true }"
                >
                  <template v-slot:body.prepend>
                    <tr class="custom-text-field-container" style="zoom: 0.8">
                      <td class="pa-0 hide-on-mobile">
                        <v-text-field disabled filled class="rounded-0 elevation-0" hide-details></v-text-field>
                      </td>
                      <td class="pa-0 hide-on-mobile">
                        <v-text-field disabled filled class="rounded-0 elevation-0" hide-details></v-text-field>
                      </td>

                      <td class="pa-0 text-field-container">
                        <v-text-field
                          v-model="aciklamaSearch"
                          :prepend-inner-icon="icons.mdiMagnify"
                          persistent-placeholder
                          placeholder="Açıklamada Ara"
                          filled
                          class="rounded-0 elevation-0"
                          hide-details
                          attach
                        ></v-text-field>
                      </td>

                      <td class="pa-0 hide-on-mobile">
                        <v-text-field disabled filled class="rounded-0 elevation-0" hide-details></v-text-field>
                      </td>
                      <td class="pa-0 hide-on-mobile">
                        <v-text-field disabled filled class="rounded-0 elevation-0" hide-details></v-text-field>
                      </td>
                      <td class="pa-0 hide-on-mobile">
                        <v-text-field disabled filled class="rounded-0 elevation-0" hide-details></v-text-field>
                      </td>
                      <td class="pa-0 hide-on-mobile">
                        <v-text-field disabled filled class="rounded-0 elevation-0" hide-details></v-text-field>
                      </td>
                      <td class="pa-0 hide-on-mobile">
                        <v-text-field disabled filled class="rounded-0 elevation-0" hide-details></v-text-field>
                      </td>
                    </tr>
                  </template>

                  <template v-slot:item.islemTarihi="{ item }">
                    <div class="">
                      {{ item.islemTarihi | moment('Do MMMM YYYY h:mm:ss') }}
                    </div>
                  </template>

                  <!--  <template v-slot:item.islemTarihi="{ item }">
                  <div class="">
                    {{ item.islemTarihi | moment('h:mm:ss') }}
                  </div>
                </template> -->

                  <template v-slot:item.tutar="{ item }">
                    <!-- <div class="" style="text-align: right">
                      {{ item.tutar | currency({ symbol: item.dovizKodu }) }}
                    </div> -->
                    <div class="d-flex justify-end" style="width: 100%">
                      <v-chip
                        :color="item.tutar < 0 ? 'red' : 'green'"
                        label
                        outlined
                        small
                        class="custom-chip d-flex justify-end"
                        :style="{
                          width: '120px',
                          border: '2px solid',
                          borderColor: item.tutar < 0 ? 'red' : 'green',
                        }"
                      >
                        {{ item.tutar | currency({ symbol: item.dovizKodu }) }}
                      </v-chip>
                    </div>
                  </template>
                  <template v-slot:item.sonBakiye="{ item }">
                    <!--  <div class="" style="text-align: right">
                      {{ item.sonBakiye | currency({ symbol: item.dovizKodu }) }}
                    </div> -->
                    <div class="d-flex justify-end" style="width: 100%">
                      <v-chip
                        :color="item.sonBakiye < 0 ? 'red' : 'green'"
                        label
                        outlined
                        small
                        class="custom-chip d-flex justify-end"
                        :style="{
                          width: '120px',
                          border: '2px solid',
                          borderColor: item.sonBakiye < 0 ? 'red' : 'green',
                        }"
                      >
                        {{ item.sonBakiye | currency({ symbol: item.dovizKodu }) }}
                      </v-chip>
                    </div>
                  </template>
                </v-data-table>
              </v-sheet>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- Yukarı Çık Butonu -->
    <v-btn
      v-if="$vuetify.breakpoint.xsOnly"
      v-show="showScrollToTop"
      class="scroll-to-top"
      @click="scrollToTop"
      fab
      fixed
      bottom
      right
      color="primary"
      dark
    >
      <v-icon> {{ icons.mdiChevronUp }} </v-icon>
    </v-btn>
  </div>
</template>

<script>
import StatisticsCardHorizontal from '@/@core/components/statistics-card/StatisticsCardHorizontal.vue'
import router from '@/router'
import store from '@/store'
import axios from '@axios'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import {
  mdiAccountCashOutline,
  mdiAccountOutline,
  mdiAccountReactivate,
  mdiArrowRightBold,
  mdiBankTransfer,
  mdiChevronDown,
  mdiChevronUp,
  mdiCircle,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiMagnify,
  mdiPoll,
} from '@mdi/js'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import Vue from 'vue'
import trackingFilter from './TrackingFilter.vue'
export default {
  components: {
    trackingFilter,
    StatisticsCardHorizontal,
    AppCardActions,
  },
  data() {
    return {
      showScrollToTop: false, // Butonun görünürlüğünü kontrol eder
      itemsPerPage: null,
      //RouterId: router.currentRoute.params.id,
      //bankalarItems_: this.bankalarItems,
      //banka: this.banka.value,
      tableItems: [],
      dataTableHeight: '',
      panel: null,
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 0 : 0,
        footer: this.$vuetify.breakpoint.smAndDown ? 0 : 0,
      },
      search: '',
      loading: false,

      icons: {
        mdiPoll,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiCircle,
        mdiMagnify,
        mdiAccountOutline,
        mdiFilter,
        mdiBankTransfer,
        mdiAccountCashOutline,
        mdiAccountReactivate,
        mdiChevronDown,
        mdiChevronUp,
        mdiAccountCashOutline,
        mdiArrowRightBold,
      },

      json_fields: {
        'İşlem Tarihi': {
          field: 'islemTarihi',
          callback: value => `${value.slice(0, 16)}`,
        },
        Unvan: 'ozelAlan2',
        Aciklama: 'aciklama',
        Tutar: {
          field: 'tutar',
          callback: value => `${value.slice(0, -1)}`,
        },
        Bakiye: {
          field: 'sonBakiye',
          callback: value => `${value.slice(0, -1)}`,
        },
        ParaBirimi: 'dovizKodu',

        Banka: 'BankaKodu',
        TipKodu: 'MT940FonksiyonKodu',
      },

      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },

  computed: {
    filteredData() {
      const conditions = []
      if (this.aciklamaSearch) {
        conditions.push(this.filterAciklama)
      }

      if (conditions.length > 0) {
        return this.dataListTable.filter(data => conditions.every(condition => condition(data)))
      }

      return this.dataListTable
    },
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  setup() {
    const refetchData = hideOverlay => {
      setTimeout(() => {
        hideOverlay()
      }, 3000)
    }
    const json_data = ref([])
    const isFilterNumber = ref(0)
    const isFilterTrackingSidebarActive = ref(false)
    const sendData = ref({})
    const startDateFilter = ref(null)
    const endDateFilter = ref(null)
    const dovizKoduFilter = ref(null)
    const bankaKoduFilter = ref(null)
    const routeId = ref(false)
    const loading = ref(false)
    const aciklamaSearch = ref('')
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
      itemsPerPage: 30,
    })
    const totalDataListTable = ref(0)

    const goBank = () => {
      router.push({ name: 'accounts-list' })
    }

    const tableColumns = computed(() => [
      {
        text: 'İşlem Tarihi',
        value: 'islemTarihi',
        width: '150px',
      },
      /*   {
        text: 'İşlem Saati',
        value: 'islemTarihi',
        width: '120px',
      }, */
      {
        text: 'Ünvan',
        value: 'ozelAlan2',
        width: '250px',
      },
      {
        text: 'Açıklama',
        value: 'aciklama',
        width: '350px',
        sortable: false,
        class: 'min-width-400',
      },
      {
        text: 'Hareket',
        value: 'hareketDurumu',
        width: '100px',
      },
      {
        text: 'Tutar',
        value: 'tutar',
        width: '150px',
      },
      {
        text: 'Bakiye',
        value: 'sonBakiye',
        width: '150px',
      },
      {
        text: 'Banka',
        value: 'BankaKodu',
        width: '120px',
      },
      {
        text: 'Tip Kodu',
        value: 'MT940FonksiyonKodu',
        width: '100px',
      },
    ])

    onMounted(() => {
      dataListTable.value = []
      accountTable.value = []
      otherAccountsTable.value = []
      fetchList()
    })
    const filterData = item => {
      startDateFilter.value = item.startDate || null
      endDateFilter.value = item.endDate || null
      dovizKoduFilter.value = item.dovizKodu || null

      bankaKoduFilter.value = item.bankaKodu || null

      let i = 0
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          i += 1
        }
      }
      isFilterNumber.value = i

      sendData.value = { ...item }
    }
    const isCardDetailsVisible = true
    const dataListTable = ref([])
    const accountTable = ref([])
    const otherAccountsTable = ref([])
    //const dovizKodu = ref('')

    const fetchList = async () => {
      let Id
      let company
      if (router.currentRoute.params.id) {
        Id = router.currentRoute.params.id
      }
      if (store.state.companyCustomer) {
        company = store.state.companyCustomer
      }
      const page = options.value.page
      const limit = options.value.itemsPerPage
      // this.loading = true
      const params = new URLSearchParams()
      params.append('method', 'getUserBankTransactions')
      if (Id !== undefined) {
        params.append('hesap_id', Id)
        routeId.value = true
      }

      params.append('company', company)
      params.append('web_api', 1)
      params.append('page', page)
      params.append('limit', limit)
      if (startDateFilter.value !== null) params.append('islem_tarihi_start', startDateFilter.value)
      if (endDateFilter.value !== null) params.append('islem_tarihi_end', endDateFilter.value)
      if (dovizKoduFilter.value !== null) params.append('doviz_kodu', dovizKoduFilter.value)
      if (bankaKoduFilter.value !== null) params.append('banka_kodu', bankaKoduFilter.value)
      axios
        .post('', params)
        .then(response => {
          if (response.data.error_code === 99) {
            Vue.swal({
              title: 'Oturum Sonlandı!',
              html: 'Geçersiz Token',
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            console.log('Oturum Sonlandı')
            router.push('/login')

            return
          }
          dataListTable.value = response.data.response.transactions || []
          accountTable.value = response.data.response.account || {}
          otherAccountsTable.value = response.data.response.other_accounts || []
          totalDataListTable.value = Number(response.data.response.count) || 0

          json_data.value = response.data.response.transactions

          /*    if (dataListTable.value.dovizKodu === 'TL') {
            dovizKodu.value = 'TRY'
          } else {
            dovizKodu.value = accountTable.value.dovizKodu
          } */

          /*  json_data.value = transactions.map(transaction => ({
            ...transaction,
            dovizKodu: dovizKodu.value, // `dovizKodu`'nu her bir işlem nesnesine ekleyin
          }))
 */
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          loading.value = false
        })
    }

    watch([startDateFilter, endDateFilter, dovizKoduFilter, bankaKoduFilter], () => {
      fetchList()
    })

    watch([options], () => {
      loading.value = true
      fetchList()
    })

    const resolveStatusVariant = status => {
      if (status === 'TL') return { color: 'primary' }
      if (status === 'EUR') return { color: 'success' }
      if (status === 'USD') return { color: 'tertiary' }
      if (status === 'Resigned') return { color: 'warning' }

      return { color: 'info' }
    }

    return {
      refetchData,
      isCardDetailsVisible,
      routeId,
      fetchList,
      dataListTable,
      accountTable,
      otherAccountsTable,
      tableColumns,
      sendData,
      isFilterNumber,
      filterData,
      isFilterTrackingSidebarActive,
      goBank,
      loading,
      aciklamaSearch,
      //dovizKodu,
      options,
      totalDataListTable,
      json_data,
      resolveStatusVariant,
      icons: {
        mdiPoll,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiCircle,
        mdiMagnify,
        mdiAccountOutline,
        mdiBankTransfer,
        mdiAccountCashOutline,
        mdiAccountReactivate,
        mdiChevronDown,
        mdiChevronUp,
        mdiAccountCashOutline,
        mdiArrowRightBold,
        mdiFilter,
      },
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (Array.isArray(this.items) && this.items.length > 0) {
        this.onResize()
        window.addEventListener('resize', this.onResize)
      }
    })
    window.addEventListener('scroll', this.handleScroll)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    navigateToTransaction(itemId) {
      // Örnek: Mevcut state'i Vuex'e kaydet
      console.log('ATL company', this.$store.state.companyCustomer)
      this.$store.commit('COMPANY_CUSTOMER', this.$store.state.companyCustomer)

      this.$router.push({ path: `/accounts/transactions/list/${itemId}` })
    },
    onResize() {
      const val = this.$vuetify.breakpoint.xsOnly ? 120 : 90
      this.dataTableHeight = window.innerHeight - val - this.height.top - this.height.footer - 1
    },
    filterAciklama(item) {
      return item.aciklama.toLowerCase().includes(this.aciklamaSearch.toLowerCase())
    },
    formatSaat(date) {
      if (!date) return ''

      return date.toLocaleTimeString() // Date nesnesini saat kısmını gösteren bir stringe dönüştürür
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    handleScroll() {
      this.showScrollToTop = window.scrollY > 200 // Sayfa kaydırma mesafesine göre butonu gösterir
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.v-data-footer {
  background-color: transparent !important;
}

.app-card-actions {
  overflow-y: auto; /* Yalnızca dikey scroll barı görüntülemek için */
  /* veya */
  //overflow: auto; /* Hem dikey hem de yatay scroll barları görüntülemek için */
  max-height: 238px; /* Opsiyonel: İçeriğin maksimum yüksekliğini belirleyebilirsiniz */
  position: relative;
  z-index: 10; /* Diğer elemanlarla olan z-index ilişkisini kontrol edin */
}
.app-card-actions-mobil {
  overflow-y: auto; /* Yalnızca dikey scroll barı görüntülemek için */
  /* veya */
  //overflow: auto; /* Hem dikey hem de yatay scroll barları görüntülemek için */
  max-height: 220px; /* Opsiyonel: İçeriğin maksimum yüksekliğini belirleyebilirsiniz */
  position: relative;
  z-index: 10; /* Diğer elemanlarla olan z-index ilişkisini kontrol edin */
}
/* Bakiye ve Tutar başlık hücrelerini sağa yasla */
.v-data-table-header th:nth-child(5), /* Bakiye */
.v-data-table-header th:nth-child(6)  /* Tutar */ {
  text-align: right !important;
}
</style>
<style scoped>
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2000; /* Sayfadaki diğer elemanların üstünde görünecek şekilde z-index ayarı yapabilirsiniz */
}

@media (max-width: 600px) {
  .container {
    /* width: calc(100% - 0px); */
    padding: 3px;
    margin: 30px auto 0;
  }
}
.account-list-expansion-panel {
  padding-top: 1.5rem; /* İhtiyacınıza göre ayarlayın */
}
.custom-chip {
  font-size: 12px; /* İhtiyaca göre ayarla */
  height: 20px; /* İhtiyaca göre ayarla */
  line-height: 20px; /* İhtiyaca göre ayarla */
}
.v-data-table td.text-danger {
  color: red !important; /* !important ile geçersiz kılma */
}

@media (max-width: 600px) {
  .custom-text-field-container {
    display: flex;
    justify-content: flex-start; /* Bileşenleri sol tarafa hizalar */
    flex-wrap: wrap; /* Gerekirse metin kutularını alt satıra taşır */
  }

  .custom-text-field-container .v-text-field {
    margin: 5px; /* Metin kutuları arasında boşluk bırakır */
    flex: 1 1 100%; /* Her bir text field'in tüm genişliği kaplamasını sağlar */
  }
  .hide-on-mobile {
    display: none; /* Mobil ekranlarda bileşeni gizler */
  }
  .custom-text-field {
    width: 100%; /* veya belirli bir genişlik, örneğin 300px */
  }
  .text-field-container {
    display: flex;
    justify-content: center; /* Ortalar */
  }

  .text-field-container .v-text-field {
    width: 460px; /* Belirli bir genişlik */
  }
}
</style>
